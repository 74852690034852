export default [
  {
    value: 'CCB',
    label: '建设银行',
  },
  {
    value: 'ICBC',
    label: '工商银行',
  },
  {
    value: 'ABC',
    label: '农业银行',
  },
  {
    value: 'PINAGAN',
    label: '平安银行',
  },
  {
    value: 'CMB',
    label: '招商银行',
  },
  {
    value: 'PSBC',
    label: '邮储银行',
  },
  {
    value: 'CMBC',
    label: '民生银行',
  },
  {
    value: 'CEB',
    label: '光大银行',
  },
  {
    value: 'CITIC',
    label: '中信银行',
  },
  {
    value: 'CIB',
    label: '兴业银行',
  },
  {
    value: 'SPDB',
    label: '浦发银行',
  },
  {
    value: 'BOC',
    label: '中国银行',
  },
  {
    value: 'COMM',
    label: '交通银行',
  },
  {
    value: 'GDB',
    label: '广发银行',
  },
  {
    value: 'HXB',
    label: '华夏银行',
  },
  {
    value: 'BJB',
    label: '北京银行',
  },
  {
    value: 'SHB',
    label: '上海银行',
  },
  {
    value: 'ZSYH',
    label: '浙商银行',
  },
  {
    value: 'HENGFENG',
    label: '恒丰银行',
  },
  {
    value: 'GLBANK',
    label: '桂林银行',
  },
  {
    value: 'LZCCB',
    label: '柳州银行',
  },
];
